import {
  ActiveEngagementPerCareArticleResponse,
  ActiveEngagementPerLearnArticleResponse,
  ActiveEngagementPerLessonResponse,
  ActiveEngagementPerQuestionnaireResponse,
  ActiveEngagementPerUserResponse,
  BaseTableRequest,
  EngagementPerSessionResponse,
  QuizzesResponse,
  ReportRequestSessionDetail,
  ReportRequestWithDate,
  ReportSessionDetail,
  Response,
  TotalEngagementPerUserResponse,
  ViewCountOfLessonsResponse,
} from 'types'
import axiosInstance from './axios'

export const getViewCountOfLessonsReport = async (request: BaseTableRequest) => {
  return axiosInstance.post<BaseTableRequest, ViewCountOfLessonsResponse>(
    '/reports/view-count-of-lessons',
    request,
  )
}

export const getActiveEngagementPerUserReport = async (request: BaseTableRequest) => {
  return axiosInstance.post<BaseTableRequest, ActiveEngagementPerUserResponse>(
    '/reports/lesson-engagement-per-user',
    request,
  )
}

export const getLearnLibraryEngagementPerUserReport = async (request: BaseTableRequest) => {
  return axiosInstance.post<BaseTableRequest, ActiveEngagementPerUserResponse>(
    '/reports/learn-article-engagement-per-user',
    request,
  )
}

export const getCareArticlesEngagementPerUserReport = async (request: BaseTableRequest) => {
  return axiosInstance.post<BaseTableRequest, ActiveEngagementPerUserResponse>(
    '/reports/care-article-engagement-per-user',
    request,
  )
}

export const getTotalEngagementPerUserReport = async (request: ReportRequestWithDate) => {
  return axiosInstance.post<BaseTableRequest, TotalEngagementPerUserResponse>(
    '/reports/total-engagement-per-user',
    request,
  )
}

export const getEngagementPerSessionReport = async (request: ReportRequestWithDate) => {
  return axiosInstance.post<BaseTableRequest, EngagementPerSessionResponse>(
    '/reports/active-engagement-per-session/master',
    request,
  )
}

export const getEngagementPerSessionDetailReport = async (request: ReportRequestSessionDetail) => {
  return axiosInstance.post<ReportRequestSessionDetail, Response<ReportSessionDetail>>(
    '/reports/active-engagement-per-session/detail',
    request,
  )
}

export const getActiveEngagementPerLessonReport = async (request: ReportRequestWithDate) => {
  return axiosInstance.post<BaseTableRequest, ActiveEngagementPerLessonResponse>(
    '/reports/active-engagement-per-lesson',
    request,
  )
}

export const getActiveEngagementPerLearnArticleReport = async (request: ReportRequestWithDate) => {
  return axiosInstance.post<BaseTableRequest, ActiveEngagementPerLearnArticleResponse>(
    '/reports/active-engagement-per-learn-article',
    request,
  )
}

export const getActiveEngagementPerCareArticleReport = async (request: ReportRequestWithDate) => {
  return axiosInstance.post<BaseTableRequest, ActiveEngagementPerCareArticleResponse>(
    '/reports/active-engagement-per-care-article',
    request,
  )
}

export const getActiveEngagementQuestionnaireReport = async (request: ReportRequestWithDate) => {
  return axiosInstance.post<BaseTableRequest, ActiveEngagementPerQuestionnaireResponse>(
    '/reports/active-engagement-per-questionnaire',
    request,
  )
}

export const getQuizzesReport = async (request: BaseTableRequest) => {
  return axiosInstance.post<BaseTableRequest, QuizzesResponse>('/reports/quizzes', request)
}

export const getQuestionnaireReport = async (request: BaseTableRequest) => {
  return axiosInstance.post<BaseTableRequest, QuizzesResponse>('/reports/questionnaires', request)
}
