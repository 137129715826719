import { Box, Button, Grid, Stack } from '@mui/material'
import React, { Dispatch, SetStateAction, useMemo } from 'react'
import { LearnArticle, Lesson } from 'types'
import { isDefined } from 'utils'
import { createMediaFile } from '../../../../api/mediaFiles'
import Breadcrumbs from '../../../../components/Breadcumbs'
import ConfirmRemoveModal from '../../../../components/modals/ConfirmRemoveModal'
import UploadAudioModal from '../../../../components/modals/UploadAudioModal'
import NotificationSys from '../../../../components/NotificationSystem'
import { useRemoveModalControl } from '../../../../hooks/useRemoveModalControl'
import { useUploadAudioModal } from '../../../../hooks/useUploadAudioModal'
import { routes } from '../../../routes'
import { EditorMode } from './types'
import { ContentType, getLoadFunc, getUpdateFunc } from './utils'

interface Props {
  mode: EditorMode
  entityType: ContentType
  onClickPreview: () => void
  onClickSave: () => void
  lessonId: number | string | undefined
  lessonName: string
  data: Lesson | LearnArticle
  setData: Dispatch<SetStateAction<Lesson | LearnArticle | null>>
}

const getHref = (entityType: ContentType) => {
  switch (entityType) {
    case 'care':
      return routes.timelinePostEdit
    case 'learn':
      return routes.learnArticleEdit
    case 'lesson':
      return routes.lessonEdit
  }
}

const EditorToolbar = (props: Props) => {
  const { mode, onClickPreview, lessonId, lessonName, onClickSave, entityType, data, setData } =
    props

  const { enMediaFileId } = data

  const loadFunc = getLoadFunc(entityType)
  const updateFunc = getUpdateFunc(entityType) as (entity: unknown) => void

  const path = useMemo(() => {
    return [
      {
        id: lessonId,
        href: getHref(entityType),
        text: lessonName,
      },
      {
        text: 'Text and Media Content',
      },
    ]
  }, [entityType, lessonId, lessonName])

  const {
    mediaLoading,
    setMediaLoading,
    isOpen,
    handleOpen,
    handleClose,
    progressBarValue,
    onUploadProgress,
  } = useUploadAudioModal()

  const onSubmit = async (file: File) => {
    setMediaLoading(true)
    try {
      const entityResponse = await loadFunc({ id: Number(data.id) })
      const mediaResponse = await createMediaFile(file, onUploadProgress)
      const newMediaId = mediaResponse.data.id
      await updateFunc({
        ...entityResponse.data,
        enMediaFileId: newMediaId,
      })
      setData((previous) => {
        if (previous) {
          return {
            ...previous,
            enMediaFileId: newMediaId,
          }
        }
        return null
      })
      NotificationSys.showSuccess('Audio successfully uploaded')
      handleClose()
    } finally {
      setMediaLoading(false)
    }
  }

  const { idToRemove, openDeleteModal, closeDeleteModal, removeLoading, handleConfirmRemove } =
    useRemoveModalControl({
      deleteFunc: async () => {
        const entityResponse = await loadFunc({ id: Number(data.id) })
        await updateFunc({
          ...entityResponse.data,
          enMediaFileId: null,
        })
        setData((previous) => {
          if (previous) {
            return {
              ...previous,
              enMediaFileId: null,
              enMediaFile: null,
            }
          }
          return null
        })
      },
      successCallback: async () => {
        NotificationSys.showSuccess('Audio successfully removed')
      },
      warning: "Can't remove audio file",
    })

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      rowSpacing={1}
      sx={{ width: '100%', maxWidth: 800 }}
    >
      <Grid item flexGrow={1000}>
        <Breadcrumbs path={path} />
      </Grid>
      <Grid item flexGrow={1}>
        <Stack direction="row" spacing={2}>
          {isDefined(data.enMediaFileId) && (
            <Button
              variant="outlined"
              color="error"
              onClick={() => openDeleteModal(Number(data.enMediaFileId))}
              sx={{ minWidth: 90 }}
            >
              Remove Audio
            </Button>
          )}
          <Button variant="outlined" onClick={handleOpen} sx={{ minWidth: 90 }}>
            {isDefined(data.enMediaFileId) ? 'Change Audio' : 'Add Audio'}
          </Button>
          <Button variant="outlined" onClick={onClickPreview} sx={{ minWidth: 90 }}>
            {mode == EditorMode.Edit ? 'Preview' : 'Edit'}
          </Button>
          <Box />
          <Button
            variant="contained"
            style={{ marginLeft: 'auto' }}
            sx={{ minWidth: 90 }}
            onClick={onClickSave}
          >
            Save
          </Button>
        </Stack>
      </Grid>

      <UploadAudioModal
        isOpen={isOpen}
        onSubmit={onSubmit}
        handleClose={handleClose}
        value={enMediaFileId}
        title="Upload Audio"
        loading={mediaLoading}
        progressBarValue={progressBarValue}
      />
      <ConfirmRemoveModal
        isOpen={isDefined(idToRemove)}
        entityToRemove="Audio"
        loading={removeLoading}
        handleClose={closeDeleteModal}
        handleConfirm={handleConfirmRemove}
      />
    </Grid>
  )
}

export default React.memo(EditorToolbar)
