import { CareArticle } from './careArticles.types'
import { CourseInTimelineItem } from './courses.types'
import { Lesson } from './lesson.types'
import { Note } from './note.types'
import { Query } from './query.types'
import { Questionnaire } from './questionnaire.types'
import { User } from './user.types'

export type QuestioningType = 'quiz' | 'query' | 'questionnaire'

export enum TimelineFilter {
  New,
  Review,
  Done,
}

export enum ETimelinePostType {
  All = 'All',
  careArticle = 'careArticle',
  taskArticle = 'taskArticle',
  lessons = 'lesson',
  note = 'note',
  learnArticles = 'learnArticle',
  queries = 'query',
  eventOnDate = 'eventOnDate',
  questionnaire = 'questionnaire',
  course = 'course',
}

export enum ETimelinePostStatus {
  new = 'new',
  review = 'review',
  retake = 'retake',
  done = 'done',
}

export const allFiltersStatuses = [
  ETimelinePostStatus.new,
  ETimelinePostStatus.review,
  ETimelinePostStatus.done,
]

export const TimelineFilterStatusMap = {
  [TimelineFilter.New]: ETimelinePostStatus.new,
  [TimelineFilter.Review]: ETimelinePostStatus.review,
  [TimelineFilter.Done]: ETimelinePostStatus.done,
}

export interface BaseTimelineItem {
  id: number
  type: ETimelinePostType
  status: ETimelinePostStatus
  userId: number
  user: User
  course: CourseInTimelineItem | null
}

export interface EventTimelineItem {
  id: string
  type: ETimelinePostType.eventOnDate
  date: string
  text: string
}

export interface CareTimelineItem extends BaseTimelineItem {
  type: ETimelinePostType.careArticle
  careArticleId: number
  careArticle: CareArticle
}

export interface TaskTimelineItem extends BaseTimelineItem {
  type: ETimelinePostType.taskArticle
  careArticleId: number
  careArticle: CareArticle
}

export interface LessonTimelineItem extends BaseTimelineItem {
  type: ETimelinePostType.lessons
  lessonId: number
  lesson: Lesson
}

interface NoteTimelineItem extends BaseTimelineItem {
  type: ETimelinePostType.note
  noteId: number
  note: Note
}

interface QueryTimelineItem extends BaseTimelineItem {
  type: ETimelinePostType.queries
  queryId: number
  query: Query
}

export interface QuestionnaireTimelineItem extends BaseTimelineItem {
  type: ETimelinePostType.questionnaire
  questionnaireId: number
  questionnaire: Questionnaire
}

export interface CourseTimelineItem {
  id: number
  type: 'course'
  name: string
  status: 'new' | 'retake' | 'done'
  bannerUrl: string | null
  items: (LessonTimelineItem | QuestionnaireTimelineItem)[]
}

export type TimelineItem =
  | CareTimelineItem
  | TaskTimelineItem
  | LessonTimelineItem
  | NoteTimelineItem
  | QueryTimelineItem
  | QuestionnaireTimelineItem

export type TimeLineWithoutEvents = Exclude<TimelineItem, EventTimelineItem>

export interface UpdateTimelineItem {
  id: number
  status: ETimelinePostStatus
  type: ETimelinePostType
}

export interface TimelineSearchResult {
  itemType: ETimelinePostType
  itemId: number
  post: string
  timelineId: number | null
  createdAt: string
  updatedAt: string
  status: ETimelinePostStatus
}
