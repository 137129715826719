import { getCareArticleById, updateCareArticle } from 'api/careArticles'
import { getLibraryArticleById, updateLibraryArticle } from 'api/learnArticles'
import { getLessonById, updateLesson } from 'api/lessons'
import { useEntityDataControl } from 'hooks/useEntityDataControl'
import { LearnArticle, Lesson, RequestById, Response } from 'types'

export type ContentType = 'learn' | 'care' | 'lesson'

export const getLoadFunc = (entityType: ContentType) => {
  switch (entityType) {
    case 'learn':
      return getLibraryArticleById
    case 'care':
      return getCareArticleById
    case 'lesson':
      return getLessonById
  }
}

export const useDataControl = (id: number | string | undefined | null, entityType: ContentType) => {
  return useEntityDataControl({
    id,
    loadFunc: getLoadFunc(entityType) as (
      request: RequestById,
    ) => Promise<Response<Lesson | LearnArticle>>,
    warning: "Can't load data of article",
  })
}

export const getUpdateFunc = (entityType: ContentType) => {
  switch (entityType) {
    case 'learn':
      return updateLibraryArticle
    case 'care':
      return updateCareArticle
    case 'lesson':
      return updateLesson
  }
}

export const getType = (type: string | undefined) => {
  switch (type) {
    case 'learn':
      return type
    case 'care':
      return type
    default:
      return 'lesson'
  }
}
