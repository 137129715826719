import { TableResponse } from './api.types'
import { Nullish } from './common.types'
import { IMetricScope } from './metric.types'
import { BaseTableRequest } from './table.types'

export type ViewCountOfLessons = {
  lessonName: string
  courseName: string
  viewCount: number
  numberOfUsers: number
}

export type ViewCountOfLessonsResponse = TableResponse<ViewCountOfLessons[]>

export interface Engagement {
  days: number
  hours: number
  minutes: number
  seconds: number
}

type ActiveEngagementPerUser = {
  userEmail: string
  userFirstName: string
  userLastName: number
  userAccessCode: string
  anonymousID: string
  lessonName: string
  courseName: string
  activeEngagement: Engagement
}

export type ActiveEngagementPerUserResponse = TableResponse<ActiveEngagementPerUser>

export type TotalEngagementPerUser = {
  userEmail: string
  userFirstName: string
  userLastName: number
  userAccessCode: string
  anonymousID: string
  totalEngagement: Engagement
  userFirstLoggedInAt: string
  userLastLoggedInAt: string
  userTotalNumbersOfLogin: number
}

// format: YYYY-MM-DD example: 2023-03-01
export interface DateRangeParam {
  fromDate: string
  toDate: string
}

export interface ReportRequestWithDate extends BaseTableRequest {
  params?: DateRangeParam
}

export type TotalEngagementPerUserResponse = TableResponse<TotalEngagementPerUser>

export type EngagementPerSession = {
  anonymousId: number
  firstName: string
  lastName: string
  email: string
  accessCode: string
  startedAt: string
  totalTime: Engagement
}

export type EngagementPerSessionResponse = TableResponse<EngagementPerSession>

export interface ReportRequestSessionDetail extends BaseTableRequest {
  params: {
    sessionUuid: string
  }
}

export interface ReportSession {
  accessCode: Nullish<string>
  anonymousId: number
  duration: Engagement
  email: string
  engagedOn: string
  firstName: string
  lastName: string
  startedAt: string
  userId: number
  uuid: string
}

export interface ReportSessionDetailItem {
  sessionUuid: string
  userId: number
  startedAt: string
  duration: Engagement
  entityType: IMetricScope
  careArticleId: Nullish<number>
  careArticleName: Nullish<string>
  leanArticleId: Nullish<number>
  leanArticleName: Nullish<string>
  lessonId: Nullish<number>
  lessonName: Nullish<string>
  queryId: Nullish<number>
  queryName: Nullish<string>
  questionnaireId: Nullish<number>
  questionnaireName: Nullish<string>
  quizId: Nullish<number>
  quizName: Nullish<string>
}

export interface ReportSessionDetail {
  session: ReportSession
  rows: ReportSessionDetailItem[]
  count: number
}

export type ActiveEngagementPerLesson = {
  lessonName: string
  courseName: string
  audioTime: Engagement
  videoTime: Engagement
  lessonTime: Engagement
  quizTime: Engagement
  totalTime: Engagement
  activeEngagement: Engagement
}

export type ActiveEngagementPerLessonResponse = TableResponse<ActiveEngagementPerLesson>

export type ActiveEngagementPerLearnArticle = {
  learnArticleName: string
  learnTopicName: string
  numberOfUsers: number
  audioTime: Engagement
  videoTime: Engagement
  learnArticleTime: Engagement
  queryTime: Engagement
  totalTime: Engagement
}

export type ActiveEngagementPerLearnArticleResponse = TableResponse<ActiveEngagementPerLearnArticle>

export type ActiveEngagementPerCareArticle = {
  careArticleName: string
  numberOfUsers: number
  audioTime: Engagement
  videoTime: Engagement
  careArticleTime: Engagement
}

export type ActiveEngagementPerCareArticleResponse = TableResponse<ActiveEngagementPerCareArticle>

export type ActiveEngagementPerQuestionnaire = {
  questionnaireName: string
  numberOfUsers: number
  audioTime: Engagement
  questionnaireTime: Engagement
}

export type ActiveEngagementPerQuestionnaireResponse =
  TableResponse<ActiveEngagementPerQuestionnaire>

export type Quizzes = {
  quizName: string
  completedCount: number
  passedCount: number
  failedCount: number
  passedPercent: number
  failedPercent: number
}

export type QuizzesResponse = TableResponse<Quizzes>

export enum EngagementReportTypes {
  Course = 'course',
  LearnLibrary = 'learnLibrary',
  CareArticle = 'careArticle',
  Total = 'total',
  Session = 'session',
}
